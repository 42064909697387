<template>
  <div class="legal">
    <!-- <div class="background"></div> -->
    <v-container>
      <v-row class="mb-10 justify-center">
        <v-col class="content_style px-10 py-11" cols="12" sm="8">
        <p class="titleName text-h3 mb-10 text-center titleName">{{ $t('disclaimer.p2')}}</p>
        <pre v-text="$t('disclaimer.p3')" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.legal{
  position: relative;
  height: 700px;
  background-image: url('/static/img/common/policyBackground.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (max-width: 450px) {
    height: 800px
  }
  // .background{
  //   position: fixed;
  //   display: block;
  //   top:0;
  //   left: 0;
  //   z-index: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-image: url('/static/img/common/policyBackground.jpg');
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   background-position: 50% 0;
  // }
  .content_style{
    z-index: 3;
  }
  .titleName {
    font-size: 50px;
    color: #ffffff;
    font-weight: 900;
    text-indent: 0em;
  }
  h2 {
    color: #ffffff;
    font-weight: 700;
    margin: 60px 0 30px 0;
    font-size: 32px;
    text-indent: 0;
  }
  p, pre {
    color: #ffffff;
    // text-indent: 1em;
    font-weight: 500;
    white-space: pre-wrap;
  }
}
</style>
